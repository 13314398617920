import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Button} from 'mw-style-react';
import './ProjectItem.scss';
import { Link } from '../../../i18n';
import Image from '../../../components/Image/Image';

class ProjectItem extends PureComponent {
  render() {
    const { item, intl } = this.props;

    const level = `${intl.formatMessage({id: 'tutorialsLevel'})}: ${intl.formatMessage({id: item.level})}`;
    const duration = `${intl.formatMessage({id: 'tutorialsDuration'})}: ${intl.formatMessage({id: item.duration})}`;

    return (
      <div className="project__item">
        <div className="project__item__header">
          <Image file={item.image} className={item.text}/>
        </div>
        <div className="project__item__content">
          <div className="project__item__title">
            {intl.formatMessage({id: item.title})}
          </div>
          {level &&
            <div className="project__item__subTitle">
              {level}
            </div>
          }
          {item.duration &&
            <div className="project__item__subTitle2">
              {duration}
            </div>
          }
          <div className="project__item__text">
            {intl.formatMessage({id: item.text})}
          </div>
        </div>
        <Link to={intl.formatMessage({id: item.button.link})} className="project__item__btn__wrapper">
          <Button
            className={'button__default project__item__btn'}
            label={intl.formatMessage({id: item.button.text})}
          />
        </Link>
      </div>
    );
  }
}


ProjectItem.propTypes = {
  item: PropTypes.object,
  intl: PropTypes.object
};

export default ProjectItem;

