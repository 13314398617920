import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
// import Demo from '../../components/Demo';
import './Tutorials.scss';
import ProjectItem from './components/ProjectItem';
import HeaderPage from '../../components/HeaderPage/HeaderPage';
import Image from '../../components/Image/Image';

class Tutorials extends PureComponent {
  render() {
    const { data, intl } = this.props;

    return (
      <section className="tutorials">
        <HeaderPage data={data.header}/>
        <div className="tutorials__content__wrapper">
          <div className="tutorials__content">
            {/* <div className="tutorials__content__title">*/}
              {/* {intl.formatMessage({id: data.content.title})}*/}
            {/* </div>*/}
            <div className="tutorials__content__list">
              {
                data.content.data.map((item, index) => (
                  <ProjectItem
                    key={index}
                    item={item}
                    intl={intl}
                  />
                ))
              }
            </div>
          </div>
        </div>
        {data.theme &&
          <div className="grid-wrapper tutorials__theme">
            <div className="col-12 tutorials__theme__title">
              {intl.formatMessage({id: data.theme.title})}
            </div>
            {
              data.theme.data.map((item, index) => (
                <div key={index} className="col-4 tutorials__theme__item">
                  <Image file={item.icon}/>
                  <div>{intl.formatMessage({id: item.text})}</div>
                </div>
              ))
            }
          </div>
        }
        {/* <Demo/> */}
      </section>
    );
  }
}


Tutorials.propTypes = {
  data: PropTypes.object,
  intl: PropTypes.object
};

export default injectIntl(Tutorials);

